// app/frontend/controllers/countdown_controller.js

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="countdown"
export default class extends Controller {
  static targets = ["timer"];

  connect() {
    console.log("Connected to countdown controller.");
    this.endTime = new Date("June 1, 2024 09:30:00").getTime();
    this.updateCountdown();
    this.interval = setInterval(() => this.updateCountdown(), 1000);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  updateCountdown() {
    const now = new Date().getTime();
    const timeLeft = this.endTime - now;

    if (timeLeft < 0) {
      this.timeTarget.innerHTML = "Countdown ended";
      clearInterval(this.interval);
      return;
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    this.timerTarget.innerHTML = `
      <div class="flex flex-col lg:flex-row items-center lg:items-baseline space-y-2 lg:space-y-0 lg:space-x-2">
        <span class="font-medium text-4xl md:text-5xl lg:text-6xl text-center uppercase text-red-500">${days}</span>
        <span class="text-lg lg:text-2xl text-black dark:text-white">days</span>
      </div>
      <div class="flex flex-col lg:flex-row items-center lg:items-baseline space-y-2 lg:space-y-0 lg:space-x-2">
        <span class="font-medium text-4xl md:text-5xl lg:text-6xl text-center uppercase text-red-500">${hours}</span>
        <span class="text-lg lg:text-2xl text-black dark:text-white">hours</span>
      </div>
      <div class="flex flex-col lg:flex-row items-center lg:items-baseline space-y-2 lg:space-y-0 lg:space-x-2">
        <span class="font-medium text-4xl md:text-5xl lg:text-6xl text-center uppercase text-red-500">${minutes}</span>
        <span class="text-lg lg:text-2xl text-black dark:text-white">minutes</span>
      </div>
      <div class="flex flex-col lg:flex-row items-center lg:items-baseline space-y-2 lg:space-y-0 lg:space-x-2">
        <span class="font-medium text-4xl md:text-5xl lg:text-6xl text-center uppercase text-red-500">${seconds}</span>
        <span class="text-lg lg:text-2xl text-black dark:text-white">seconds</span>
      </div>
    `;
  }
}
