import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nav"
export default class extends Controller {
  static values = { state: Boolean };
  static targets = ["menu", "bars", "x", "link"];

  connect() {
    console.log("Nav controller connected");
    console.log(this.stateValue);
  }

  toggleNav() {
    this.stateValue = !this.stateValue;

    if (this.stateValue) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  openMenu() {
    this.menuTarget.classList.remove("slide--out");
    this.menuTarget.classList.add("slide--in");
    this.menuTarget.classList.remove("hidden");
    document.body.classList.add("modal--open");
  }

  closeMenu() {
    this.menuTarget.classList.remove("slide--in");
    this.menuTarget.classList.add("slide--out");
    this.menuTarget.classList.add("hidden");
    document.body.classList.remove("modal--open");
  }

  hideModal() {
    this.stateValue = false; // Ensure the menu is closed
    this.closeMenu();
  }
}
