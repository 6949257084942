// Import necessary modules and styles
import "~/stylesheets/application.css";
import "~/controllers";
import "@hotwired/turbo-rails";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as cookieconsent from "vanilla-cookieconsent";

// Global dataLayer initialization for Google Analytics
window.dataLayer = window.dataLayer || [];

// Define gtag function
function gtag() {
  if (window.dataLayer && typeof window.dataLayer.push === "function") {
    window.dataLayer.push(arguments);
  } else {
    console.error("dataLayer is not properly initialized");
  }
}

// Assign gtag to window to make it globally available
window.gtag = gtag;

// Function to initialize Google Analytics
function initializeGoogleAnalytics() {
  console.log("Initializing Google Analytics");

  let script = document.createElement("script");
  script.async = true;
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-681M591KBH";
  script.setAttribute("nonce", "<%= nonce %>");

  script.onload = function () {
    console.log("Google Analytics script loaded");
    setTimeout(function () {
      console.log("Running gtag commands");
      gtag("js", new Date());
      gtag("config", "G-681M591KBH");
    }, 1000);
  };

  script.onerror = function () {
    console.error("Failed to load Google Analytics script");
  };

  document.head.appendChild(script);
}

document.addEventListener("DOMContentLoaded", function () {
  // Initialize cookie consent with proper configuration
  function initializeCookieConsent() {
    if (cookieconsent.run) {
      cookieconsent.run({
        guiOptions: {
          consentModal: {
            layout: "box",
            position: "bottom right",
            equalWeightButtons: true,
            flipButtons: false,
          },
          preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false,
          },
        },
        categories: {
          necessary: {
            enabled: true, // this category is enabled by default
            readOnly: true, // this category cannot be disabled
          },
          analytics: {
            enabled: false,
            readOnly: false,
            autoClear: {
              cookies: [
                {
                  name: /^_ga/, // regex: match all cookies starting with '_ga'
                },
                {
                  name: /^_gid/, // regex: match all cookies starting with '_gid'
                },
              ],
            },
          },
        },
        language: {
          default: "en",
          translations: {
            en: {
              consentModal: {
                title: "We use cookies",
                description:
                  "We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic.",
                acceptAllBtn: "Accept all",
                acceptNecessaryBtn: "Reject all",
                showPreferencesBtn: "Manage preferences",
              },
              preferencesModal: {
                title: "Manage cookie preferences",
                acceptAllBtn: "Accept all",
                acceptNecessaryBtn: "Reject all",
                savePreferencesBtn: "Accept current selection",
                closeIconLabel: "Close modal",
                sections: [
                  {
                    title: "Necessary cookies",
                    description:
                      "These cookies are essential for the website to function properly.",
                    linkedCategory: "necessary",
                  },
                  {
                    title: "Analytics cookies",
                    description:
                      "These cookies help us understand how visitors interact with the website.",
                    linkedCategory: "analytics",
                  },
                ],
              },
            },
          },
        },
        onFirstConsent: ({ cookie }) => {
          console.log("First consent received", cookie);
        },
        onConsent: ({ cookie }) => {
          console.log("Consent updated", cookie);
          if (cookie.categories.includes("analytics")) {
            initializeGoogleAnalytics("<%= nonce %>");
          }
        },
        onChange: ({ changedCategories, changedServices }) => {
          console.log("Consent changed", changedCategories, changedServices);
          if (changedCategories.includes("analytics")) {
            if (cookieconsent.acceptedCategory("analytics")) {
              initializeGoogleAnalytics("<%= nonce %>");
            } else {
              // Code to disable Google Analytics
              window["ga-disable-G-57BXFE2C88"] = true;
            }
          }
        },
      });
    }
  }

  initializeCookieConsent();
});

// Dark mode theme handling
if (
  localStorage.theme === "dark" ||
  (!("theme" in localStorage) &&
    window.matchMedia("(prefers-color-scheme: dark)").matches)
) {
  document.documentElement.classList.add("dark");
} else {
  document.documentElement.classList.remove("dark");
}

// Save scroll position before unloading the page
window.addEventListener("beforeunload", function () {
  if (window.scrollY !== 0) {
    // Check if scroll position is not at the top
    sessionStorage.setItem("scrollPosition", window.scrollY);
  }
});

// Restore scroll position when the page is loaded
window.addEventListener("load", function () {
  let scrollPosition = sessionStorage.getItem("scrollPosition");
  if (scrollPosition !== null) {
    window.scrollTo(0, scrollPosition);
    sessionStorage.removeItem("scrollPosition"); // Clear the saved scroll position
  }
});

// Vite client and JavaScript tag logging for development
console.log("Vite ⚡️ Rails");
console.log(
  "Visit the guide for more information: ",
  "https://vite-ruby.netlify.app/guide/rails",
);
