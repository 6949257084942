import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nav"
export default class extends Controller {
  static values = { state: Boolean };
  static targets = ["info"];

  connect() {
    console.log("Info controller connected");
    console.log(this.stateValue);
  }

  toggleInfo() {
    this.stateValue = !this.stateValue;

    if (this.stateValue) {
      this.openInfo();
    } else {
      this.closeInfo();
    }
  }

  openInfo() {
    this.infoTarget.classList.remove("slide--out");
    this.infoTarget.classList.add("slide--in");
    this.infoTarget.classList.toggle("hidden");
    document.body.classList.add("modal--open");
  }

  closeInfo() {
    this.infoTarget.classList.remove("slide--in");
    this.infoTarget.classList.add("slide--out");
    this.infoTarget.classList.add("hidden");
    document.body.classList.remove("modal--open");
  }

  hideModal() {
    this.stateValue = false; // Ensure the menu is closed
    this.closeInfo();
  }
}
