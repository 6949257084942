import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    console.log("Back to top controller connected");
    this.toggleButtonVisibility(); // Initially toggle visibility
    this.scrollListener(); // Listen for scroll events
  }

  toggleButtonVisibility() {
    const header = document.getElementById("header");
    const backToTopBtn = this.buttonTarget;

    // Get the height of the home section
    const homeHeight = header.offsetHeight;

    // Check if the user has scrolled past the home section
    if (window.scrollY > homeHeight) {
      backToTopBtn.classList.remove("hidden"); // Show the button
    } else {
      backToTopBtn.classList.add("hidden"); // Hide the button
    }
  }

  scrollToTop() {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  }

  // Listen for scroll events and toggle button visibility
  scrollListener() {
    window.addEventListener("scroll", () => {
      this.toggleButtonVisibility();
    });
  }
}
